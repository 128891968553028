import dynamic from 'next/dynamic'
import { MultiImage } from '@/components/image'
import { styled } from 'stitches.config'

const ProductFeaturedPopup = dynamic(() => import('@/components/product/product-featured-popup'))

const ContentImage = styled(MultiImage, {
  height: '500px',
  '@md': { height: '400px' },
  '@lg': { height: '500px' },
  '@xl': { height: '600px' },
})

const ContentImageBlock = (props) => {
  const desktopImage = props?.backgroundImageDesktop?.url
  const tabletImage = props?.backgroundImageTablet?.url || props?.backgroundImageDesktop?.url
  const mobileImage =
    props?.backgroundImageMobile?.url ||
    props?.backgroundImageTablet?.url ||
    props?.backgroundImageDesktop?.url
  const popupPosition = props?.popupPosition || 'right'

  return (
    <>
      <div className="content-image-block relative bg-center bg-cover w-full h-full">
        <ContentImage
          className="content-image-block-img-wrap object-cover"
          {...{
            priority: true,
            quality: 85,
            srcMap: {
              mobile: mobileImage,
              tablet: tabletImage,
              desktop: desktopImage,
            },
          }}
        />
        {props.productData?.length > 0 && (
          <ProductFeaturedPopup
            position={popupPosition}
            productsData={props.productData}
            className={`z-10`}
          />
        )}
      </div>
    </>
  )
}

export default ContentImageBlock
